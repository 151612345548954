import MetText from "common/met-text/met-text";

const MetBrand = () => {
  return (
    <MetText size="xl" family="pacifico" className="text-white">
      Metamorfosis
    </MetText>
  );
};

export default MetBrand;
