import React, { FC } from "react";

interface MetTextProps {
  family?: "pacifico";
  size?: "base" | "sm" | "xs" | "xl" | "lg" | "2xl" | "4xl";
  block?: boolean;
  className?: string;
}
const MetText: FC<MetTextProps> = ({
  family = "Open Sans",
  size = "base",
  block,
  children,
  className,
}) => {
  return (
    <div
      className={`text-${size} ${className} d-${block ? "block" : "inline"}`}
      style={{
        // display: block ? "block" : "inline",
        fontSize: size,
        fontFamily: family,
      }}
    >
      {children}
    </div>
  );
};

export default MetText;
