import DataPt from "./Data.json";
import DataEn from "./DataEnglish.json";
import DataEs from "./DataSpanish.json";
import { TextsEnglish } from "./TextsEnglish";
import { TextsPortuguese } from "./TextsPortuguese";
import { TextsSpanish } from "./TextsSpanish";
// import DataEn from './DataEnglish.json.json';

export const Language = (function () {
  const lang = localStorage.getItem("lang");

  const navigatorLanguage = navigator.language.toLowerCase();
  const urlParams = new URLSearchParams(window.location.search);
  const paramLang = urlParams.get("lang");

  if (["pt", "en", "es"].includes(paramLang ?? "XXX")) {
    localStorage.setItem("lang", paramLang as string);
    return paramLang;
  }

  if (lang) {
    return lang;
  }

  if (navigatorLanguage.indexOf("pt") > -1) {
    return "pt";
  }

  if (navigatorLanguage.indexOf("es") > -1) {
    return "es";
  }

  return "en";
})();

export const Translations = (function () {
  if (Language === "pt") {
    return TextsPortuguese;
  }
  if (Language === "es") {
    return TextsSpanish;
  }

  return TextsEnglish;
})();

export const TranslatedData = (function () {
  if (Language === "pt") {
    return DataPt;
  }
  if (Language === "es") {
    return DataEs;
  }

  return DataEn;
})();
