import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MetNavbar from "./common/met-navbar/met-navbar";

import AppRouter from "./router/app-router";

const App = () => {
  return (
    <div>
      <Suspense fallback={null}>
        <Router>
          <MetNavbar />
          <div className="pt-20">
            <AppRouter />
          </div>
        </Router>
      </Suspense>
      {/* <LandingPage /> */}
    </div>
  );
};

export default App;
