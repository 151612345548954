import React from "react";
import { FC } from "react";
import { NavbarItem } from "./navbar-items";

interface MetNavbarItemProps {
  item: NavbarItem;
}
const MetNavbarItem: FC<MetNavbarItemProps> = ({ item }) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    if (item.scrollTo) {
      const target = document.getElementById(item.scrollTo);
      target?.scrollIntoView({ behavior: "smooth" });
    }
    return false;
  };

  return (
    <a
      onClick={handleClick}
      href={item.href}
      className="block md:inline-block text-white hover:text-secondary px-3 py-3  md:border-none"
    >
      {item.text}
    </a>
  );
};

export default MetNavbarItem;
