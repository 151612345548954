import React from "react";
import MetBrand from "common/met-brand/met-brand";
import MetNavbarItem from "./met-navbar-item";
import { NAVBAR_ITEMS } from "./navbar-items";

const MetNavbar = () => {
  const onToggle = () => {
    const navToggle = document.getElementsByClassName("toggle");
    for (let i = 0; i < navToggle.length; i++) {
      navToggle.item(i)?.classList.toggle("hidden");
    }
  };

  return (
    <nav className="flex flex-wrap items-center justify-between p-5 bg-primary fixed w-full">
      <MetBrand />
      <div className="flex md:hidden">
        <button onClick={onToggle} id="hamburger">
          <img
            alt=""
            className="toggle block"
            src="https://img.icons8.com/fluent-systems-regular/2x/menu-squared-2.png"
            width="40"
            height="40"
            style={{ filter: "invert(1)" }}
          />
          <img
            alt=""
            className="toggle hidden"
            src="https://img.icons8.com/fluent-systems-regular/2x/close-window.png"
            width="40"
            height="40"
            style={{ filter: "invert(1)" }}
          />
        </button>
      </div>
      <div className="toggle hidden md:flex w-full md:w-auto text-right text-bold mt-5 md:mt-0 border-t-2  md:border-none">
        {NAVBAR_ITEMS.map((item) => (
          <MetNavbarItem key={`navbar-item-${item.text}`} item={item} />
        ))}
      </div>
      {/* <a
        href="#"
        className="toggle hidden md:flex w-full md:w-auto px-4 py-2 text-right bg-blue-900 hover:bg-blue-500 text-white md:rounded"
      >
        Create Account
      </a> */}
    </nav>
  );
};

export default MetNavbar;
