import { sendSlackMessage } from "./slack.service";

const downloadUrl = "https://metamorfosisapp.page.link/Download";

export class SiteUtilsService {
  static downloadApp() {
    document.location.href = downloadUrl;
  }

  static getMobileOperatingSystem() {
    var userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      return "iOS";
    } else if (userAgent.match(/Android/i)) {
      return "Android";
    } else {
      return "unknown";
    }
  }

  static sendSlackMessage(message: string) {
    const os = this.getMobileOperatingSystem();
    const platformEmoji =
      os === "Android" ? ":robot_face:" : os === "iOS" ? ":apple:" : `*${os}*`;
    sendSlackMessage(`${platformEmoji} - ${message}`);
  }

  static openMugStore() {
    document.location.href =
      "https://metamorfosisapp.mercadoshops.com.br/MLB-1857448597-caneca-inteligente-metamorfosis-sua-dose-diaria-de-reflexo-_JM";
  }
}
