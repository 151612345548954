import { SiteUtilsService } from "services/link.service";

export interface NavbarItem {
  text: string;
  href?: string;
  onClick?: () => void;
  scrollTo?: string;
}
export const NAVBAR_ITEMS: NavbarItem[] = [
  { text: "Recursos", href: "#features-section", scrollTo: "features-section" },
  { text: "Benefícios", href: "#benefits-area", scrollTo: "benefits-section" },
  { text: "Caneca Inteligente", href: "#mug-area", scrollTo: "mug-section" },
  { text: "Parcerias", href: "#partner-area", scrollTo: "partner-section" },
  {
    text: "Contato",
    onClick: () => {
      SiteUtilsService.sendSlackMessage("Contato clicked");
      window.location.href =
        "mailto:metamorfosisapp@gmail.com?subject=CONTATO VIA SITE&body=Escreva aqui sua mensagem";

      setTimeout(() => {
        alert(
          "Caso o seu aplicativo de e-mail não tenha sido aberto, envie um e-mail com sua mensagem para metamorfosisapp@gmail.com com o assunto CONTATO"
        );
      }, 4000);
    },
  },
];
