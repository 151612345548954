// https://hooks.slack.com/services/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

import axios from "axios";
import { Language } from "../content/Texts";

export const sendSlackMessage = async (message) => {
  var url = 'https://hooks.slack.com/services/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'
  const icon = Language === 'es' ? ':flag-es:' : Language === 'pt' ? ':flag-br:' : ':flag-us:';
  await axios.post(url, `payload=${JSON.stringify({ text: `${icon} ${message} - ${getOS()}` })}`);
}

export const sendSlackMessageApoie = async (message) => {
  var url = 'https://hooks.slack.com/services/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'
  const icon = Language === 'es' ? ':flag-es:' : Language === 'pt' ? ':flag-br:' : ':flag-us:';
  await axios.post(url, `payload=${JSON.stringify({ text: `${icon} ${message} - ${getOS()}` })}`);
}

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}