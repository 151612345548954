import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import App from "./App";

// const App = () => (
//   <BrowserRouter>
//     <I18nextProvider i18n={i18n}>
//       <Router />
//     </I18nextProvider>
//   </BrowserRouter>
// );

ReactDOM.render(<App />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
