const routes = [
  {
    path: ["/premium/:result"],
    exact: true,
    component: "premium-result",
  },
  {
    path: ["/premium"],
    exact: true,
    component: "premium",
  },
  {
    path: ["/minha-caneca"],
    exact: true,
    component: "minha-caneca",
  },
  {
    path: ["/", "/home"],
    exact: true,
    component: "landing",
  },
  {
    path: ["/download"],
    exact: true,
    component: "download",
  },
  {
    path: ["/resultado-teste/:result"],
    exact: true,
    component: "test-result",
  },
  {
    path: ["/teste"],
    exact: true,
    component: "Teste",
  },
  {
    path: ["/teste/perguntas"],
    exact: true,
    component: "Perguntas",
  },
  {
    path: ["/teste/resultado"],
    exact: true,
    component: "Resultado",
  },
  {
    path: ["/apoie"],
    exact: true,
    component: "Support",
  },
  {
    path: ['/politica-de-privacidade'],
    exact: true,
    component: 'politica'
  }
];

export default routes;
