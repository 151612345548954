import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import routes from "./routes";
const AppRouter = () => {
  return (
    <Switch>
      {routes.map((routeItem) => {
        return (
          <Route
            key={routeItem.component}
            path={routeItem.path}
            exact={routeItem.exact}
            component={lazy(
              () =>
                import(
                  `../pages/${routeItem.component}-page/${routeItem.component}-page`
                )
            )}
          />
        );
      })}
    </Switch>
  );
};

export default AppRouter;
